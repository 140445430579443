<script setup lang="ts">
import { AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';

interface Props {
  id: string;
  title: string;
  items?: {
    link: {
      url?: string;
      label: string;
      [key: string]: any;
    };
    id?: string | null;
  }[];
}

const { id, title, items = [] } = defineProps<Props>();
</script>

<template>
  <AccordionItem :key="id" :value="id">
    <AccordionTrigger
      class="px-6 py-8 text-lg font-thin hover:text-secondary-foreground [&[data-state=open]]:font-bold [&[data-state=open]]:text-secondary-foreground">
      {{ title }}
      <template #icon>
        <slot name="trigger-icon" />
      </template>
    </AccordionTrigger>
    <AccordionContent>
      <nav class="flex flex-col gap-y-3 px-6 pb-8">
        <template v-if="items?.length">
          <a 
            v-for="item in items" 
            :key="item.id || item.link.label"
            class="text-white text-base no-underline" 
            :href="item.link.url"
            v-bind="{ ...item.link }"
          >
            {{ item.link.label }}
          </a>
        </template>
      </nav>
    </AccordionContent>
  </AccordionItem>
</template>
