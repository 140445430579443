<script setup lang="ts">
import { cn } from '@/utils'
import {
  AccordionHeader,
  AccordionTrigger,
  type AccordionTriggerProps,
} from 'radix-vue'
import { computed, type HTMLAttributes } from 'vue'

const props = defineProps<AccordionTriggerProps & { 
  class?: HTMLAttributes['class'],
  chevronClass?: string 
}>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})
</script>

<template>
  <AccordionHeader class="flex">
    <AccordionTrigger
      v-bind="delegatedProps"
      :class="
        cn(
          'flex flex-1 items-center justify-between py-4 font-medium transition-all hover:underline [&[data-state=open]_svg]:rotate-180',
          props.class,
        )
      "
    >
      <slot />
      <slot name="icon" />
    </AccordionTrigger>
  </AccordionHeader>
</template>
