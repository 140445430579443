<script setup>
import { Accordion } from '@/components/ui/accordion';
import FooterMobileGroup from './FooterMobileGroup.vue';
const props = defineProps({
  informationMenuHeadline: {
    type: String,
    required: true
  },
  topSmartphonesMenuHeadline: {
    type: String,
    required: true
  },
  customerSupportMenuHeadline: {
    type: String,
    required: true
  },
  informationMenu: {
    type: Array,
    required: true
  },
  topSmartphonesMenu: {
    type: Array,
    required: true
  },
  customerSupportMenu: {
    type: Array,
    required: true
  },
});

</script>

<template>
  <Accordion type="single" collapsible :defaultValue="informationMenu[0].link.label">
    <FooterMobileGroup
      :id="'footer-acc-information'"
      :title="informationMenuHeadline ?? ''"
      :items="informationMenu"
    >
      <template #trigger-icon>      
        <slot name="trigger-icon" />
      </template>
    </FooterMobileGroup>  

    <FooterMobileGroup
      :id="'footer-acc-top-smartphones'"
      :title="topSmartphonesMenuHeadline ?? ''"
      :items="topSmartphonesMenu"
    >
      <template #trigger-icon>
        <slot name="trigger-icon" />
      </template>
    </FooterMobileGroup>

    <FooterMobileGroup
      :id="'footer-acc-customer-support'"
      :title="customerSupportMenuHeadline ?? ''"
      :items="customerSupportMenu"
    >
      <template #trigger-icon>
        <slot name="trigger-icon" />
      </template>
    </FooterMobileGroup>
  </Accordion>
</template>
